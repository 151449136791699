import {is_desktop} from 'desktop';
import {fromDesktop} from 'hit';

let menu = document.getElementById('menu_dropdown');
var is_signup = window.location.pathname.includes('signup') || document.getElementById('homepage');

if (menu) {
  menu.addEventListener('click', (event) => {
    let cover = document.getElementById('header_menu_dropin');
    let icon = document.getElementById('top_menu_hamburger');
    cover.classList = cover.classList.contains('shown') ?  '' : 'shown';
    icon.classList = icon.classList.contains('ion-android-menu') ?  'ion-close' : 'ion-android-menu';
  });
}

if (!is_signup || fromDesktop) {
  adjust_header();
  window.addEventListener('resize', adjust_header, true);
}

function adjust_header()  {
  let body_size = document.getElementById('whole_page').offsetWidth;
  if (body_size > 1080) {
    document.getElementById('main_body').classList.add('desktop');
     // trigger desktop css
    document.getElementById('mobile_header_menu').classList = 'hidden';   
    document.getElementById('desktop_header_menu').classList = '';   
  }
  else {
    document.getElementById('mobile_header_menu').classList = '';   
    document.getElementById('desktop_header_menu').classList = 'hidden';   
  }
}
